<template>
  <div class="px-16px py-20px grid grid-cols-10 gap-20px">
    <div
      class="col-span-3 flex flex-col bg-ems-gray800 py-13px max-h-[calc(100vh-60px-40px)]"
    >
      <div class="px-20px">
        <div class="flex justify-between items-center mb-20px">
          <div
            class="flex gap-6px items-center text-ems-gray200 font-semibold text-18px uppercase"
          >
            <div
              v-if="route.query.keyword && windowConst.history.state.back"
              class="hover:bg-ems-gray700 cursor-pointer"
              @click="onBack"
            >
              <img
                src="/assets/ems/left.png"
                alt="left"
                class="block mx-auto"
                width="24"
                height="24"
              />
            </div>
            {{ t('event_verify.list_profile') }}
          </div>
          <div>
            <SelectFilter
              class="min-w-130px"
              :options="OBJECT_TYPE_LIST"
              v-model:value="formStateProfile.type"
              :placeholder="t('event_verify.category')"
              :allow-clear="false"
              @change="onSearchProfile"
            >
            </SelectFilter>
          </div>
        </div>
        <InputFilter
          class="!rounded-4px event-verify"
          v-model:value="formStateProfile.keyword"
          :show-count="false"
          :maxlength="100"
          :placeholder="t('event_verify.placeholder_keyword')"
          @input="onChangeInput"
        >
          <template #suffix>
            <img src="../../static/img/icon/IconSearch.svg" alt="IconSearch" />
          </template>
        </InputFilter>
        <div class="mt-10px">
          <SelectFilter
            ref="relateSug"
            class="event-verify"
            :options="listGroupByUser"
            :label-prop="'name'"
            :value-prop="'id'"
            v-model:value="formStateProfile.groupIds"
            :placeholder="t('event_verify.group')"
            :allow-clear="true"
            :multiple="true"
            :max-tag-count="2"
            :max-tag-text-length="13"
            @change="onSearchProfile"
          >
          </SelectFilter>
        </div>
      </div>
      <div class="relative h-full overflow-hidden mt-22px">
        <div v-if="isLoadingListProfile" class="spin-custom">
          <cds-loading />
        </div>
        <div
          ref="refProfile"
          class="flex flex-col gap-16px h-full pl-20px pr-8px overflow-y-auto hidden-scroll"
          @scroll="onScrollListProfile"
        >
          <template v-for="item in listProfile" :key="item.id">
            <div
              class="relative p-16px w-full rounded-8px flex gap-22px border-1px cursor-pointer hover:border-ems-main2"
              :class="
                currentProfileId === item.id
                  ? 'border-ems-main2 bg-ems-gray600'
                  : 'border-transparent bg-ems-gray700'
              "
              @click="onGetListEvent(item.id, item.name)"
            >
              <img
                :src="item.imagesAvatar"
                class="flex-shrink-0 w-120px h-120px object-cover rounded-8px"
                :alt="item.imagesAvatar"
              />
              <div class="flex flex-col w-full truncate">
                <div
                  class="text-16px leading-25px mb-5px text-ems-white font-semibold w-full truncate"
                >
                  <span>{{ item.name ? item.name : item.id }}</span>
                </div>
                <ul
                  class="list-disc list-inside text-12px text-ems-gray500 font-semibold pl-5px event-verify-ul"
                >
                  <template v-if="item.type === OBJECT_TYPE.HUMAN">
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.date_of_birth') }}:
                      {{
                        item.human
                          ? formatDate(item.human.date_of_birth, 'DD/MM/YYYY')
                          : ''
                      }}
                    </li>
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.address') }}:
                      {{ item.human ? item.human.address : '' }}
                    </li>
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.phone') }}:
                      {{ item.human ? item.human.phone : '' }}
                    </li>
                  </template>
                  <template v-if="item.type === OBJECT_TYPE.VEHICLE">
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.category') }}:
                      {{
                        item.vehicle
                          ? getNameVehicleType(item.vehicle.vehicle_type)
                          : ''
                      }}
                    </li>
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.brand') }}:
                      {{ item.vehicle ? item.vehicle.brand_id : '' }}
                    </li>
                    <li class="leading-19px mb-5px">
                      {{ t('event_verify.color') }}:
                      {{
                        item.vehicle
                          ? vehicleColor(item.vehicle.vehicle_color)
                          : ''
                      }}
                    </li>
                  </template>
                </ul>
                <div
                  class="bg-transparent w-[fit-content] text-ems-boTro4_600 border-1px border-ems-boTro4_600 rounded-23px px-16px py-3px leading-19px text-12px cursor-pointer"
                  @click="viewDetailProfile(item)"
                >
                  {{ t('event_verify.view_detail') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="col-span-7 bg-ems-gray800 pt-23px pb-13px max-h-[calc(100vh-60px-40px)] flex flex-col"
    >
      <div
        class="text-ems-gray200 font-semibold text-18px mb-18px px-20px uppercase"
      >
        {{ t('event_verify.suggest_event') }}
      </div>
      <div class="flex justify-between px-20px">
        <div class="flex gap-17px">
          <div>
            <cds-select
              v-model:value="formStateEvent.similarity"
              class="event-verify min-w-143px"
              :options="SIMILARITY_LIST"
              :allow-clear="true"
              :placeholder="t('event_verify.similarity')"
              @change="onSearchEvent"
            />
          </div>
          <div>
            <cds-date-picker
              v-model:value="formStateEvent.dateFrom"
              class="event-verify"
              placeholder="DD/MM/YYYY"
              :disabled-date="disabledDateFrom"
              @change="onSearchEvent"
            />
          </div>
          <div>
            <cds-date-picker
              v-model:value="formStateEvent.dateTo"
              class="event-verify"
              placeholder="DD/MM/YYYY"
              :disabled-date="disabledDateTo"
              @change="onSearchEvent"
            />
          </div>
        </div>
        <div class="flex gap-16px">
          <ClipButton
            v-if="checkHasPermission(perms, EVENT_UNVERIFIED_PERM.MERGE)"
            class="flex items-center !px-5"
            :type="'primary'"
            :disabled="listEventSelected.length < 1"
            @click="onMerge"
          >
            <span>{{ t('event_verify.merge_event') }}</span>
          </ClipButton>
          <ClipButton
            v-if="checkHasPermission(perms, EVENT_UNVERIFIED_PERM.MOVE)"
            class="flex items-center !px-5"
            :type="'primary'"
            :disabled="listEventSelected.length < 1"
            @click="openDrawer = true"
          >
            <span>{{ t('event_verify.move_profile') }}</span>
          </ClipButton>
          <ClipButton
            v-if="checkHasPermission(perms, EVENT_UNVERIFIED_PERM.DELETE)"
            class="flex items-center !px-5"
            :type="'primary'"
            :disabled="listEventSelected.length < 1"
            @click="onDelete"
          >
            <span>{{ t('common.delete') }}</span>
          </ClipButton>
        </div>
      </div>
      <div class="h-1px bg-ems-gray700 my-20px mx-20px"></div>
      <div
        class="bg-ems-gray700 rounded-4px py-8px px-24px flex justify-between items-center mx-20px"
      >
        <div class="flex items-center gap-30px">
          <div class="text-ems-white text-22px font-semibold leading-35px">
            {{ currentProfileName }}
          </div>
          <div
            class="h-33px border-1px border-ems-gray600 rounded-27px leading-25px px-24px py-4px"
          >
            <span class="text-ems-white text-16px"
              >{{ t('event_verify.display') }}:
            </span>
            <span class="text-ems-main2 font-bold text-16px">{{
              numberDisplayEvents
            }}</span>
            <span class="text-ems-gray400 text-16px"> / {{ totalEvent }}</span>
          </div>
        </div>
        <div class="flex items-center gap-72px">
          <div class="text-16px text-ems-white">
            {{ t('event_verify.selected') }}
            <span class="text-ems-main1 font-bold">{{
              listEventSelected.length
            }}</span>
          </div>
          <div>
            <a-checkbox
              v-model:checked="isAllChecked"
              class="text-ems-white text-16px"
              @change="onChecked"
              >{{ t('event_verify.select_all') }}
            </a-checkbox>
          </div>
        </div>
      </div>
      <div class="relative h-full overflow-hidden mt-20px">
        <div v-if="isLoadingListEvent" class="spin-custom">
          <cds-loading />
        </div>
        <Empty
          v-if="listEvent.length === 0"
          class="m-auto"
          :description="t('common.no_data_displayed')"
        />
        <div
          v-else
          ref="refEvent"
          class="grid grid-cols-5 gap-20px overflow-y-auto hidden-scroll max-h-full pl-20px pr-9px"
          @scroll="onScrollListEvent"
        >
          <div v-for="item in listEvent" :key="item">
            <div
              class="relative group bg-ems-gray700 hover:bg-ems-gray600 rounded-8px p-10px border-1px cursor-pointer"
              :class="
                isChecked(item.id) ? 'border-ems-main2' : 'border-transparent'
              "
              @click="onSelectedEvent(item.id)"
            >
              <a-popover
                placement="right"
                :trigger="'click'"
                color="#000"
                overlay-class-name="event-verify-popover"
              >
                <template #content>
                  <img :src="item.data.imageUrl" :alt="item.data.imageUrl" />
                  <div
                    class="px-25px grid grid-cols-2 gap-20px pt-30px pb-25px"
                  >
                    <div
                      class="border-b border-ems-gray700 flex flex-col justify-between"
                    >
                      <div class="text-ems-gray500 text-14px">
                        {{ t('event_verify.camera') }}
                      </div>
                      <div
                        class="text-ems-gray300 text-16px mt-7px leading-25px"
                      >
                        {{ item.data.cameraName }}
                      </div>
                    </div>
                    <div
                      class="border-b border-ems-gray700 flex flex-col justify-between"
                    >
                      <div class="text-ems-gray500 text-14px">
                        {{ t('event_verify.location') }}
                      </div>
                      <div
                        class="text-ems-gray300 text-16px mt-7px leading-25px"
                      >
                        {{ item.data.cameraLocation }}
                      </div>
                    </div>
                    <div
                      class="border-b border-ems-gray700 flex flex-col justify-between"
                    >
                      <div class="text-ems-gray500 text-14px">
                        {{ t('event_verify.time') }}
                      </div>
                      <div
                        class="text-ems-gray300 text-16px mt-7px leading-25px"
                      >
                        {{
                          formatDate(
                            item.data.createdAt,
                            'HH:mm:ss - DD/MM/YYYY'
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="border-b border-ems-gray700 flex flex-col justify-between"
                    >
                      <div class="text-ems-gray500 text-14px">
                        {{ t('event_verify.status') }}
                      </div>
                      <div
                        class="text-16px mt-7px leading-25px"
                        :class="getStatusStyle(item.data.status)"
                      >
                        {{ getStatusLabel(item.data.status) }}
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="bg-ems-boTro4_700 absolute right-16px top-94px rounded-3px hidden group-hover:block"
                  @click="($event) => $event.stopPropagation()"
                >
                  <div class="text-ems-white text-10px px-7px py-5px">
                    {{ t('common.view_detail') }}
                  </div>
                </div>
              </a-popover>
              <img
                v-if="isChecked(item.id)"
                src="../../static/img/icon/CheckedIcon.svg"
                class="absolute right-16px top-16px"
                alt="CheckedIcon"
              />
              <img
                :src="item.data.imageUrl"
                class="w-full h-110px object-cover rounded-8px"
                :alt="item.data.imageUrl"
              />
              <div class="flex justify-between items-center h-25px mt-8px">
                <div class="text-12px font-semibold text-ems-gray300">
                  {{ t('event_verify.similarity') }}
                </div>
                <div
                  class="text-16px font-semibold"
                  :class="
                    Math.round(item.data.similarityData.face_match_percent) > 60
                      ? 'text-ems-boTro4_600'
                      : 'text-ems-white'
                  "
                >
                  {{ Math.round(item.data.similarityData.face_match_percent) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <showConfirmModal ref="showConfirmCustom" :width="'500px'"></showConfirmModal>
  <drawer-custom
    :visible="openDrawer"
    :width="450"
    :title="t('event_verify.move_to_profile')"
    @onClose="openDrawer = false"
    @onCancel="openDrawer = false"
    :component="MoveToOtherProfile"
    @moveToProfile="onMoveToOtherProfile"
    :show-btn-search="false"
    @onHide="openDrawer = false"
  />
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import SelectFilter from '@/components/select-filter/index.vue';
import InputFilter from '@/components/input-filter/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { Empty, notification } from 'ant-design-vue';
import { debounce } from 'lodash';
import {
  EVENT_STATUS_VALUE,
  OBJECT_TYPE,
  FORM_MODE,
  EVENT_UNVERIFIED_PERM,
} from '@/util/common-constant';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { useRoute, useRouter } from 'vue-router';
import {
  formatDate,
  checkHasPermission,
  hasPermEdit,
} from '@/util/common-utils';
import dayjs from 'dayjs';

const MoveToOtherProfile = defineAsyncComponent(() =>
  import('./components/MoveToOtherProfile.vue')
);
const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);

const { t } = useI18n();
const { state, dispatch } = useStore();
const perms = computed(() => state.auth.userInfo.permissions);
const listProfile = computed(() => state.eventVerify.listProfile);
const listEvent = computed(() => state.eventVerify.listEvent);
const totalEvent = computed(() => state.eventVerify.totalEvent);
const listGroupByUser = computed(() => state.ioGroup.listGroupByUser);
const color = computed(() => state.listSelect.color);
const vehicleColor = computed(() => (currentColor) => {
  const index = color.value.findIndex((item) => item.value === currentColor);
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
});
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = computed(() => (currentType) => {
  const index = vehicleType.value.findIndex(
    (item) => item.value === currentType
  );
  if (index !== -1) {
    return vehicleType.value[index].name;
  }
  return '';
});
const formStateProfile = reactive({
  keyword: null,
  type: 1,
  groupIds: [],
});
const formStateEvent = reactive({
  similarity: null,
  percentGte: null,
  percentLte: null,
  dateFrom: null,
  dateTo: null,
});
const openDrawer = ref(false);
const refProfile = ref(null);
const refEvent = ref(null);
const currentProfileName = ref('');
const currentProfileId = ref('');
const isLoadingListProfile = ref(false);
const isLoadingListEvent = ref(false);
const pageListProfile = ref(0);
const pageListEvent = ref(0);
const listEventSelected = ref([]);
const isAllChecked = ref(false);
const numberDisplayEvents = computed(() => listEvent.value.length);
const onChecked = () => {
  if (isAllChecked.value) {
    listEventSelected.value = listEvent.value.map((item) => item.id);
  } else {
    listEventSelected.value = [];
  }
};
const onSelectedEvent = (id) => {
  const index = listEventSelected.value.findIndex((item) => item === id);
  if (index > -1) {
    listEventSelected.value.splice(index, 1);
  } else {
    listEventSelected.value.push(id);
  }
  isAllChecked.value =
    listEventSelected.value.length === listEvent.value.length;
};
const isChecked = computed(() => (id) => {
  const index = listEventSelected.value.findIndex((item) => item === id);
  return index > -1;
});
const route = useRoute();
onMounted(async () => {
  await dispatch('setTitleHeader', t('event_verify.title'));
  if (route.query.keyword) {
    formStateProfile.keyword = route.query.keyword;
  }
  isLoadingListProfile.value = true;
  const response = await dispatch('eventVerify/getListProfile', {
    keyword: formStateProfile.keyword,
    type: formStateProfile.type,
    reset: true,
  });
  isLoadingListProfile.value = false;
  if (response) {
    isLoadingListEvent.value = true;
    currentProfileName.value = listProfile.value[0].name
      ? listProfile.value[0].name
      : listProfile.value[0].id;
    currentProfileId.value = listProfile.value[0].id;
    listEventSelected.value = [];
    await dispatch('eventVerify/getListEventVerify', {
      ...formStateEvent,
      percentGte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentGte
        : null,
      percentLte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentLte
        : null,
      ioId: listProfile.value[0].id,
    });
    isLoadingListEvent.value = false;
  }
  const promises = [
    dispatch('ioGroup/getAllGroupByUser'),
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllVehicleType'),
  ];
  await Promise.all(promises);
});
const onGetListEvent = async (id, name) => {
  currentProfileName.value = name || id;
  currentProfileId.value = id;
  isLoadingListEvent.value = true;
  listEventSelected.value = [];
  pageListEvent.value = 0;
  await dispatch('eventVerify/getListEventVerify', {
    ...formStateEvent,
    percentGte: formStateEvent.similarity
      ? JSON.parse(formStateEvent.similarity).percentGte
      : null,
    percentLte: formStateEvent.similarity
      ? JSON.parse(formStateEvent.similarity).percentLte
      : null,
    ioId: id,
    reset: true,
  });
  isAllChecked.value =
    listEventSelected.value.length === listEvent.value.length;
  isLoadingListEvent.value = false;
};
const onChangeInput = debounce(() => {
  onSearchProfile();
}, 500);
const onSearchProfile = async () => {
  isLoadingListProfile.value = true;
  pageListProfile.value = 0;
  refProfile.value.scrollTo({ top: 0, behavior: 'instant' });
  const response = await dispatch('eventVerify/getListProfile', {
    ...formStateProfile,
    page: pageListProfile.value,
    reset: true,
  });
  isLoadingListProfile.value = false;
  currentProfileName.value = '';
  currentProfileId.value = '';
  if (response && listProfile.value && listProfile.value.length > 0) {
    isLoadingListEvent.value = true;
    currentProfileName.value = listProfile.value[0].name
      ? listProfile.value[0].name
      : listProfile.value[0].id;
    currentProfileId.value = listProfile.value[0].id;
    listEventSelected.value = [];
    await dispatch('eventVerify/getListEventVerify', {
      ...formStateEvent,
      percentGte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentGte
        : null,
      percentLte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentLte
        : null,
      ioId: listProfile.value[0].id,
      reset: true,
    });
    isLoadingListEvent.value = false;
  }
};
const onScrollListProfile = async ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    isLoadingListProfile.value = true;
    pageListProfile.value += 1;
    await dispatch('eventVerify/getListProfile', {
      ...formStateProfile,
      page: pageListProfile.value,
    });
    isLoadingListProfile.value = false;
  }
};
const onSearchEvent = async () => {
  isLoadingListEvent.value = true;
  pageListEvent.value = 0;
  if (refEvent.value) {
    refEvent.value.scrollTo({ top: 0, behavior: 'instant' });
  }
  listEventSelected.value = [];
  await dispatch('eventVerify/getListEventVerify', {
    ...formStateEvent,
    percentGte: formStateEvent.similarity
      ? JSON.parse(formStateEvent.similarity).percentGte
      : null,
    percentLte: formStateEvent.similarity
      ? JSON.parse(formStateEvent.similarity).percentLte
      : null,
    ioId: currentProfileId.value,
    page: pageListEvent.value,
    reset: true,
  });
  isAllChecked.value =
    listEventSelected.value.length === listEvent.value.length;
  isLoadingListEvent.value = false;
};
const onScrollListEvent = async ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (numberDisplayEvents.value === totalEvent.value) {
    return;
  }
  if (scrollTop + clientHeight >= scrollHeight) {
    isLoadingListEvent.value = true;
    pageListEvent.value += 1;
    await dispatch('eventVerify/getListEventVerify', {
      ...formStateEvent,
      percentGte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentGte
        : null,
      percentLte: formStateEvent.similarity
        ? JSON.parse(formStateEvent.similarity).percentLte
        : null,
      ioId: currentProfileId.value,
      page: pageListEvent.value,
      reset: false,
    });
    isAllChecked.value =
      listEventSelected.value.length === listEvent.value.length;
    isLoadingListEvent.value = false;
  }
};
const showConfirmCustom = ref(null);
const onMerge = async () => {
  if (await showConfirmCustom.value.onOpenModal('common.merge_event_confirm')) {
    const response = await dispatch('eventVerify/mergeEvent', {
      id: currentProfileId.value,
      idsSimilar: listEventSelected.value,
    });
    if (response) {
      notification.success({
        message: t('event_verify.merge_event_success'),
        description: '',
        duration: 4,
      });
      await onSearchEvent();
    } else {
      notification.error({
        message: t('event_verify.merge_event_fail'),
        description: '',
        duration: 4,
      });
    }
  }
};
const onDelete = async () => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_event')) {
    dispatch('eventVerify/delete', listEventSelected.value).then(() => {
      onSearchEvent();
    });
  }
};
const onMoveToOtherProfile = async (selectedProfile) => {
  if (
    await showConfirmCustom.value.onOpenModal(
      'event_verify.move_to_profile_confirm'
    )
  ) {
    const response = await dispatch('eventVerify/mergeEvent', {
      id: selectedProfile,
      idsSimilar: listEventSelected.value,
    });
    if (response) {
      openDrawer.value = false;
      notification.success({
        message: t('event_verify.move_to_profile_success'),
        description: '',
        duration: 4,
      });
      await onSearchEvent();
    } else {
      notification.error({
        message: t('event_verify.move_to_profile_success'),
        description: '',
        duration: 4,
      });
    }
  }
};
const windowConst = window;
const onBack = () => {
  if (window.history.state.back) {
    history.back();
  }
};
const getStatusStyle = (status) => {
  switch (status) {
    case EVENT_STATUS_VALUE.INTRUSION:
      return 'text-ems-main1';
    case EVENT_STATUS_VALUE.CHECKOUT:
      return 'text-ems-boTro4_600';
    case EVENT_STATUS_VALUE.CHECKIN:
      return 'text-ems-boTro500';
    default:
      return 'text-ems-boTro2_600';
  }
};
const getStatusLabel = (status) => {
  switch (status) {
    case EVENT_STATUS_VALUE.INTRUSION:
      return t('identification-event.intrusion');
    case EVENT_STATUS_VALUE.CHECKOUT:
      return t('identification-event.checkout');
    case EVENT_STATUS_VALUE.CHECKIN:
      return t('identification-event.checkin');
    default:
      return t('identification-event.appear');
  }
};
const router = useRouter();
const viewDetailProfile = (record) => {
  const routeLink = router.resolve({
    path: '/io-management/object-management/edit',
    query: {
      id: record.id,
      type: record.type,
      mode: hasPermEdit(perms.value, 'object')
        ? FORM_MODE.UPDATE
        : FORM_MODE.VIEW,
      tab: 'history',
    },
  });
  window.open(routeLink.href, '_blank');
};
const disabledDateFrom = (current) => {
  return current && current > dayjs(formStateEvent.dateTo).endOf('day');
};
const disabledDateTo = (current) => {
  return current && current < dayjs(formStateEvent.dateFrom).startOf('day');
};
onUnmounted(() => {
  dispatch('eventVerify/resetForm');
});
const OBJECT_TYPE_LIST = [
  { label: 'object_information.human', value: 1 },
  // { label: 'object_information.vehicle', value: 2 },
];
const SIMILARITY_LIST = [
  {
    label: '45-59%',
    value: JSON.stringify({ percentGte: 45, percentLte: 60 }), // Gồm cả case giữa 59-60%
  },
  {
    label: '60-70%',
    value: JSON.stringify({ percentGte: 60, percentLte: 70 }),
  },
];
</script>
<style>

.event-verify-ul li::marker {
  font-size: 0.7em;
}

.event-verify {
  .ant-select-selector {
    border-radius: 4px !important;
  }

  .ant-picker {
    border-radius: 4px !important;
  }

  .ant-select-multiple {
    border-radius: 4px !important;
  }

  .ant-input-prefix {
    margin: 0 !important;
  }
}

.event-verify.ant-input-affix-wrapper > input.ant-input {
  padding-left: 0 !important;
}

.event-verify-popover {
  .ant-popover-inner {
    border: 2px solid #4c4c4c;
    padding: 0;
    width: 600px;
  }

  .ant-popover-arrow {
    display: none;
  }
}

</style>
